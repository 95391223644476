import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import { ArticleProps } from './models';
import { getUrl, getTarget } from '../../utils/browser';
import 'styles/main.scss';
import './Article.scss';

const Article: FC<{ data: ArticleProps }> = ({
  data: {
    article: { title, image, body, seoMetaTitle, seoMetaKeywords, seoMetaDescription },
    backButtonArticle: {
      back: [back],
    },
  },
}) => (
  <Layout headerTransparent>
    <Seo {...{ title: seoMetaTitle, description: seoMetaDescription, keywords: seoMetaKeywords }} />
    <PageSchema
      type="WebPage"
      name={seoMetaTitle}
      data={{
        metaTitle: seoMetaTitle,
        metaDescription: seoMetaDescription,
        metaKeywords: seoMetaKeywords,
      }}
    />
    <div id="article">
      <section id="article__banner" className="text-center">
        <Image imageData={image} alt={image.altText} />
      </section>
      <section id="article__middle">
        <div className="container-fluid">
          <div className="row">
            <div className="col py-4">
              <h2 className="line-primary pt-4">{title}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col pb-5">
              <DangerouslySetInnerHtml html={body} element="div" className="article__body" />
            </div>
          </div>
          <div className="row">
            <div className="col pb-5 text-right">
              <a
                className="btn btn-secondary my-2 text-white"
                href={getUrl(back)}
                target={getTarget(back)}
              >
                {back.name}
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export const query = graphql`
  query($link: String) {
    article(link: { eq: $link }) {
      title
      seoMetaTitle
      seoMetaKeywords
      seoMetaDescription
      body
      image {
        fallbackUrl
        altText
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1024)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1024)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1024)
          }
        }
      }
    }
    backButtonArticle {
      back {
        name
        url
      }
    }
  }
`;

export default Article;
